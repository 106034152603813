import React from 'react'
import tw, {styled} from "twin.macro";
import checkIcon from "../../../../ui-treact/images/checkIconWhite.png";
import Desk from 'src/images/Desk.jpg'
const FocusDiv = styled.div`
${tw`flex flex-col items-start mt-4 text-left w-full`}
`
const Focus = styled.div`
  ${tw`flex flex-row items-start px-0 lg:px-4 mt-3 text-left`}
  img {
    ${tw`w-4 h-4 max-w-full`}
  }
  span{
    ${tw`-mt-1 px-2 text-sm font-medium text-white`}
  }
`;
const focuses = [
    {
      id: 1,
      heading: 'No long-term contracts.',
      img_src: checkIcon
    },
    {
      id: 2,
      heading: 'Flexible programs.',
      img_src: checkIcon
    },
    {
      id: 3,
      heading: 'Affordable flat rate pricing.',
      img_src: checkIcon
    },
    {
      id: 4,
      heading: 'Experience you can trust.',
      img_src: checkIcon
    },
    {
      id: 5,
      heading: 'User-friendly services, leave you in control!',
      img_src: checkIcon
    }
  ]
const focusesDives = focuses?.map((focus) =>{
    return(
        <Focus>
          <img src={focus.img_src} alt="" />                
          <span>{focus.heading || "Fully Secure"}</span>
        </Focus>
    )
  })
export default function AboutHero() {
    return (
        <div tw="py-8 flex bg-white w-full justify-center items-center">
            <div tw="relative bg-gray-800 rounded-xl">
            <div tw="h-56 bg-secondary-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 rounded-xl overflow-hidden">
            <img tw="w-full h-full object-cover" src={Desk} alt="" />
            </div>
            <div tw="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <div tw="md:ml-auto md:w-1/2 md:pl-10">
                <h2 tw="text-base font-semibold uppercase tracking-wider text-gray-300">About Us</h2>
                <p tw="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">Our Start</p>
                <p tw="mt-3 text-base text-gray-300">We have been blessed to grow and expand our reach in the digital marketing industry, year after year.  We began this venture out of necessity, out of being tired of being strung along by web developers, SEO experts, and the like whom never seemed to be available or be able to show any tangible results. <br /> <br />If you are tired of having to hire someone to make one small website change, or with outlandish hourly rates, we get it! We were once there!  That’s why we have dedicated our talents to this industry to help in an honest, transparent way.</p>
                {focusesDives ? <FocusDiv>{focusesDives}</FocusDiv> : null}
                <div tw="mt-8">
                </div>
            </div>
            </div>
        </div>
        </div>

    )
}
